<template>
    <div>
        <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'" class="d-flex align-center">
                                <div>
                                    <h5>Match records</h5>
                                </div>
                            </v-col>
                            <v-spacer />
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="getTeamsHurlingSort"
                                        v-model="overallTeam" @change="updateOverallResults" label="Team"
                                        item-text="name" item-value="id" return-object outlined hide-details
                                        hide-no-data>
                                        <!-- Custom rendering for dropdown items -->
                                        <template v-slot:item="{ item, on, attrs }">
                                            <v-list-item v-bind="attrs" v-on="on">
                                                <v-list-item-avatar>
                                                    <v-img :src="`/assets/gaa/teams/icons/${item.code}.WEBP`" contain
                                                        max-width="30" class="img_border"></v-img>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>

                                        <!-- Custom rendering for selected item -->
                                        <template v-slot:selection="{ item }">
                                            <div style="display: flex; align-items: center;">
                                                <v-img :src="`/assets/gaa/teams/icons/${item.code}.WEBP`" contain
                                                    max-width="20" max-height="20" class="img_border"
                                                    style="margin-right: 5px;"></v-img>
                                                <span>{{ item.name }}</span>
                                            </div>
                                        </template>
                                    </v-select>
                                    <v-combobox v-else :items="getTeamsHurlingSort" v-model="overallTeam"
                                        @change="updateOverallResults" label="Team" item-text="name" item-value="id"
                                        return-object outlined hide-details hide-no-data>
                                        <!-- Custom rendering for dropdown items -->
                                        <template v-slot:item="{ item, on, attrs }">
                                            <v-list-item v-bind="attrs" v-on="on">
                                                <v-list-item-avatar>
                                                    <v-img :src="`/assets/gaa/teams/icons/${item.code}.WEBP`" contain
                                                        max-width="30" class="img_border"></v-img>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>

                                        <!-- Custom rendering for selected item -->
                                        <template v-slot:selection="{ item }">
                                            <div style="display: flex; align-items: center;">
                                                <v-img :src="`/assets/gaa/teams/icons/${item.code}.WEBP`" contain
                                                    max-width="20" max-height="20" class="img_border"
                                                    style="margin-right: 5px;"></v-img>
                                                <span>{{ item.name }}</span>
                                            </div>
                                        </template>
                                    </v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" id="opposition_combo"
                                        :disabled="!overallTeam" :items="overallOppositionOption"
                                        v-model="overallOpposition" @change="updateOverallFilter" multiple
                                        label="Opposition" item-text="name" return-object outlined hide-details
                                        hide-no-data clearable>
                                        <template #selection="{ item }">
                                            <span v-if="overallOpposition.length === 1">{{ item.name }}</span>
                                            <span v-else-if="item.name == overallOpposition[0].name">{{
                                                overallOpposition.length }}
                                                teams</span>
                                        </template>
                                    </v-select>
                                    <v-combobox v-else id="opposition_combo" :disabled="!overallTeam"
                                        :items="overallOppositionOption" v-model="overallOpposition"
                                        @change="updateOverallFilter" multiple label="Opposition" item-text="name"
                                        return-object outlined hide-details hide-no-data clearable>
                                        <template #selection="{ item }">
                                            <span v-if="overallOpposition.length === 1">{{ item.name }}</span>
                                            <span v-else-if="item.name == overallOpposition[0].name">{{
                                                overallOpposition.length }}
                                                teams</span>
                                        </template>
                                    </v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="overallHomeAwayOptions"
                                        v-model="overallHomeFilter" @change="overallHomeFilterChanged" item-text="label"
                                        return-object label="Venue" outlined hide-details hide-no-data></v-select>
                                    <v-combobox v-else :items="overallHomeAwayOptions" v-model="overallHomeFilter"
                                        @change="overallHomeFilterChanged" item-text="label" return-object label="Venue"
                                        outlined hide-details hide-no-data></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'" class="d-flex align-center">
                                <div>
                                    <v-btn class="grey" :class="this.$vuetify.theme.dark ? 'darken-2' : 'lighten-2'"
                                        @click="showOverallFilters = !showOverallFilters">
                                        {{ showOverallFilters ? 'Filters' : 'Filters' }}
                                        <v-icon class="ml-1">{{ showOverallFilters ? 'mdi-arrow-up-drop-circle-outline'
                                            :
                                            'mdi-arrow-down-drop-circle-outline' }}</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <transition name="fade">
                            <v-row v-if="showOverallFilters"
                                :class="this.$vuetify.breakpoint.xs ? 'settings_height_mobile' : 'settings_height'">
                                <v-spacer />
                                <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                    <div>
                                        <v-select v-if="$vuetify.breakpoint.xsOnly" :items="overallYearFromFilters"
                                            v-model="overallYearFromFilter" @change="overallYearFromFilterChanged"
                                            label="Year from" outlined hide-details hide-no-data></v-select>
                                        <v-combobox v-else :items="overallYearFromFilters"
                                            v-model="overallYearFromFilter" @change="overallYearFromFilterChanged"
                                            label="Year from" outlined hide-details hide-no-data></v-combobox>
                                    </div>
                                </v-col>
                                <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                    <div>
                                        <v-select v-if="$vuetify.breakpoint.xsOnly" :items="overallYearToFilters"
                                            v-model="overallYearToFilter" @change="overallYearToFilterChanged"
                                            label="Year to" outlined hide-details hide-no-data></v-select>
                                        <v-combobox v-else :items="overallYearToFilters" v-model="overallYearToFilter"
                                            @change="overallYearToFilterChanged" label="Year to" outlined hide-details
                                            hide-no-data></v-combobox>
                                    </div>
                                </v-col>
                                <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                    <div>
                                        <v-select v-if="$vuetify.breakpoint.xsOnly" :items="getOverallFilteredStadiums"
                                            @change="overallStadiumFilterChanged" v-model="overallStadiumFilter"
                                            item-text="label" item-value="value" label="Stadium" outlined hide-details
                                            hide-no-data></v-select>
                                        <v-combobox v-else :items="getOverallFilteredStadiums"
                                            @change="overallStadiumFilterChanged" v-model="overallStadiumFilter"
                                            item-text="label" item-value="value" label="Stadium" outlined hide-details
                                            hide-no-data></v-combobox>
                                    </div>
                                </v-col>
                                <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                    <div>
                                        <v-select v-if="$vuetify.breakpoint.xsOnly" :items="overallStageOptions"
                                            @change="overallStageFilterChanged" v-model="overallStageFilter"
                                            item-text="label" item-value="value" label="Stage" outlined hide-details
                                            hide-no-data></v-select>
                                        <v-combobox v-else :items="overallStageOptions"
                                            @change="overallStageFilterChanged" v-model="overallStageFilter"
                                            item-text="label" item-value="value" label="Stage" outlined hide-details
                                            hide-no-data></v-combobox>
                                    </div>
                                </v-col>
                                <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                    <div>
                                        <v-select v-if="$vuetify.breakpoint.xsOnly" :items="getRoundOptions"
                                            @change="overallRoundFilterChanged" v-model="overallRoundFilter"
                                            item-text="label" item-value="value" label="Round" outlined hide-details
                                            hide-no-data></v-select>
                                        <v-combobox v-else :items="getRoundOptions" @change="overallRoundFilterChanged"
                                            v-model="overallRoundFilter" item-text="label" item-value="value"
                                            label="Round" outlined hide-details hide-no-data></v-combobox>
                                    </div>
                                </v-col>
                            </v-row>
                        </transition>
                        <div v-if="overallTeam == null || getFilteredOverallHurlingResults.length == 0" class="caption">
                            Select a team and at
                            least one opponent to populate table</div>
                    </v-card-title>
                    <v-card-text>
                        <div id="head-to-head-table-container">
                            <div id="head-to-head-table">
                                <div v-if="overallTeam" style="display: flex; justify-content: space-evenly;">
                                    <span style="color: green;" class="mr-2">Green is a {{ overallTeam.name }}
                                        win</span>
                                    <span style="color: red;">Red is a {{ overallTeam.name }} loss</span>
                                </div>
                                <transition name="fade">
                                    <v-data-table :headers="activeHeaders" :items="getFilteredOverallHurlingResults"
                                        class="elevation-1" mobile-breakpoint="0">

                                        <template v-slot:item.result="{ item }">
                                            <div style="display: flex;">
                                                <v-menu offset-y v-if="item.note">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon v-bind="attrs" v-on="on" small
                                                            class="mr-2">mdi-information-outline</v-icon>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item>
                                                            <v-list-item-title>{{ item.note }}</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                                <span :style="calculateResultColour(item)">{{ calculateResultText(item)
                                                    }}</span>
                                            </div>
                                        </template>

                                        <template v-slot:item.opposition="{ item }"
                                            v-if="this.$vuetify.breakpoint.xsOnly">
                                            <v-img :src="item.homeTeam && item.homeTeam.id == overallTeam.id ? `/assets/gaa/teams/icons/${item.awayTeam.code}.WEBP`
                                                : `/assets/gaa/teams/icons/${item.homeTeam.code}.WEBP`" contain
                                                max-width="30" class="img_border"></v-img>
                                            {{ item.homeTeam && item.homeTeam.id == overallTeam.id ? item.awayTeam.name
                                                :
                                                item.homeTeam.name }}
                                        </template>

                                        <template v-slot:item.opposition="{ item }" v-else>
                                            <span style="width: 100%; height: 100%; cursor: pointer;" @click="goToProfile(item.homeTeam && item.homeTeam.id == overallTeam.id ? item.awayTeam.id
                                                : item.homeTeam.id)">
                                                <v-img :src="item.homeTeam && item.homeTeam.id == overallTeam.id ? `/assets/gaa/teams/icons/${item.awayTeam.code}.WEBP`
                                                    : `/assets/gaa/teams/icons/${item.homeTeam.code}.WEBP`"
                                                    alt="Opponent Logo" contain max-width="30"
                                                    class="img_border cursor-pointer"></v-img></span>
                                        </template>

                                        <template v-slot:item.oppositionName="{ item }">
                                            <h6 class="ma-0 font-weight-medium cursor-pointer" @click="goToProfile(item.homeTeam && item.homeTeam.id == overallTeam.id ? item.awayTeam.id
                                                : item.homeTeam.id)">
                                                {{ item.homeTeam && item.homeTeam.id == overallTeam.id ?
                                                    item.awayTeam.name
                                                    : item.homeTeam.name }}
                                            </h6>
                                        </template>

                                        <template v-slot:item.date="{ item }">
                                            {{ `${item.date[2]}/${item.date[1]}/${item.date[0]}` }}
                                        </template>

                                    </v-data-table>
                                </transition>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex/dist/vuex.common.js';
export default {
    name: 'HeadToHeadTable',
    async mounted() {
        await this.fetchTeamsBySport(this.$router.currentRoute.meta.sport.toUpperCase());
        this.populateQueryParams();
        this.loading = false;
    },
    data() {
        return {
            loading: true,
            overallTeam: null,
            overallOpposition: [],
            overallYearFromFilter: 'All',
            overallYearToFilter: 'All',
            overallStadiumFilter: 'All',
            overallStageFilter: 'All',
            overallRoundFilter: 'All',
            overallHomeFilter: null,
            showOverallFilters: false,
            overallTableHeaders: [
                {
                    text: 'Opposition',
                    sortable: false,
                    value: 'opposition'
                },
                {
                    text: '',
                    sortable: false,
                    value: 'oppositionName'
                },
                { text: 'Score', value: 'result', sortable: false },
                {
                    text: 'Round',
                    sortable: false,
                    value: 'round'
                },
                {
                    text: 'Competition',
                    sortable: false,
                    value: 'stage'
                },
                { text: 'Date', value: 'date', sortable: false },
                { text: 'Stadium', value: 'venue', sortable: false },
                {
                    text: 'Location',
                    sortable: false,
                    value: 'location'
                },
            ]
        }
    },
    computed: {
        ...mapGetters(["getTeamsHurlingSort", "getFilteredOverallHurlingResults", "getFilteredOverallHurlingResults", "getOverallFilteredStadiums", "getOverallResults", "getRoundOptions"]),
        activeHeaders() {
            if (this.$vuetify.breakpoint.xsOnly) {
                return this.overallTableHeaders.filter(it => it.value != 'oppositionName' && it.value != 'round' && it.value != 'location')
            } else {
                return this.overallTableHeaders;
            }
        },
        overallOppositionOption() {
            let choices = this.$store.getters['getTeamsHurlingSort'].map(it => it);
            if (this.overallTeam) {
                choices = choices.filter(it => it.id != this.overallTeam.id)
            }
            if (this.overallOpposition && this.overallOpposition.length > 0) {
                choices = choices.filter(it => it.id != this.overallOpposition.id)
            }
            choices.unshift({ name: "Connacht", grouper: true });
            choices.unshift({ name: "Ulster", grouper: true });
            choices.unshift({ name: "Leinster", grouper: true });
            choices.unshift({ name: "Munster", grouper: true });
            choices.unshift({ name: "All", grouper: true });
            return choices;
        },
        overallHomeAwayOptions() {
            let teamOptions = [{ "label": 'All', "value": null }, { "label": "Neutral", "value": -1 }];
            if (this.overallTeam) {
                teamOptions.push({ "label": this.overallTeam.name + ' home', "value": this.overallTeam.id });
                teamOptions.push({ "label": this.overallTeam.name + ' away', "value": this.overallTeam.id * -1 });
            }
            return teamOptions;
        },
        overallStageOptions() {
            let stages = ['All', 'Munster', 'Leinster', 'All Ireland'];
            return stages;
        },
        overallYearFromFilters() {
            let years = ['All'];
            if (this.getFilteredOverallHurlingResults) {
                years.push(...this.getFilteredOverallHurlingResults.map(it => it.year).sort((a, b) => a - b));
            }
            return years;
        },
        overallYearToFilters() {
            let years = ['All'];
            if (this.getFilteredOverallHurlingResults) {
                years.push(...this.getFilteredOverallHurlingResults.map(it => it.year));
            }
            return years;
        }
    },
    methods: {
        ...mapActions(["fetchTeamsBySport", "fetchOverallResults", "updateOverallOppositionFilter", "updateOverallStadiumFilter", "updateOverallStageFilter",
            "updateOverallYearToFilter", "updateOverallYearFromFilter", "updateOveralldHomeFilter", "updateOverallRoundFilter"
        ]),
        updateOverallResults() {
            if (this.overallTeam) {
                this.fetchOverallResults({ "teamId": this.overallTeam.id, "competitionId": 2 }); // TODO - "competitionId": 2 still present
                this.$router.push({
                    path: this.$route.path,
                    query: {
                        ...this.$route.query,
                        team: this.overallTeam.id
                    }
                });
            }
        },
        updateOverallFilter() {
            if (this.overallOpposition.find(it => it.name == 'All')) {
                this.updateOverallOppositionFilter([]);
            } else if (this.overallOpposition.find(it => it.name == 'Munster')) {
                let updatedTeams;
                if (this.$router.currentRoute.meta.sport == 'hurling') {
                    updatedTeams = [...this.overallOpposition, { id: 30 }, { id: 31 }, { id: 38 }, { id: 43 }, { id: 52 }, { id: 54 }];
                } else {
                    updatedTeams = [...this.overallOpposition, { id: 64 }, { id: 65 }, { id: 72 }, { id: 77 }, { id: 86 }, { id: 88 }];
                }
                updatedTeams = updatedTeams.filter(it => it.id != this.overallTeam.id);
                this.updateOverallOppositionFilter(updatedTeams);
            } else if (this.overallOpposition.find(it => it.name == 'Leinster')) {
                let updatedTeams;
                if (this.$router.currentRoute.meta.sport == 'hurling') {
                    updatedTeams = [...this.overallOpposition, { id: 28 }, { id: 35 }, { id: 39 }, { id: 40 }, { id: 41 }, { id: 44 }, { id: 45 }, { id: 47 }, { id: 49 }, { id: 55 }, { id: 56 }, { id: 57 }]
                } else {
                    updatedTeams = [...this.overallOpposition, { id: 62 }, { id: 69 }, { id: 73 }, { id: 74 }, { id: 75 }, { id: 78 }, { id: 79 }, { id: 81 }, { id: 83 }, { id: 89 }, { id: 90 }, { id: 91 }]
                }
                updatedTeams = updatedTeams.filter(it => it.id != this.overallTeam.id);
                this.updateOverallOppositionFilter(updatedTeams);
            } else if (this.overallOpposition.find(it => it.name == 'Ulster')) {
                let updatedTeams;
                if (this.$router.currentRoute.meta.sport == 'hurling') {
                    updatedTeams = [...this.overallOpposition, { id: 26 }, { id: 27 }, { id: 29 }, { id: 32 }, { id: 33 }, { id: 34 }, { id: 36 }, { id: 48 }, { id: 53 }]
                } else {
                    updatedTeams = [...this.overallOpposition, { id: 60 }, { id: 61 }, { id: 63 }, { id: 66 }, { id: 67 }, { id: 68 }, { id: 70 }, { id: 82 }, { id: 87 }]
                }
                updatedTeams = updatedTeams.filter(it => it.id != this.overallTeam.id);
                this.updateOverallOppositionFilter(updatedTeams);
            } else if (this.overallOpposition.find(it => it.name == 'Connacht')) {
                let updatedTeams;
                if (this.$router.currentRoute.meta.sport == 'hurling') {
                    updatedTeams = [...this.overallOpposition, { id: 37 }, { id: 42 }, { id: 46 }, { id: 50 }, { id: 51 }]
                } else {
                    updatedTeams = [...this.overallOpposition, { id: 71 }, { id: 76 }, { id: 80 }, { id: 84 }, { id: 85 }]
                }
                updatedTeams = updatedTeams.filter(it => it.id != this.overallTeam.id);
                this.updateOverallOppositionFilter(updatedTeams);
            } else {
                if (this.overallOpposition && this.overallOpposition.length == 0) {
                    this.updateOverallOppositionFilter(null);
                } else {
                    this.updateOverallOppositionFilter(this.overallOpposition);
                }
            }
            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    opposition: this.overallOpposition.map(team => team.id ? team.id : team.name)
                }
            });
        },
        overallStageFilterChanged() {
            this.updateOverallStageFilter(this.overallStageFilter);
        },
        overallRoundFilterChanged() {
            this.updateOverallRoundFilter(this.overallRoundFilter);
        },
        overallYearFromFilterChanged() {
            this.updateOverallYearFromFilter(this.overallYearFromFilter);
        },
        overallYearToFilterChanged() {
            this.updateOverallYearToFilter(this.overallYearToFilter);
        },
        overallHomeFilterChanged() {
            this.updateOveralldHomeFilter(this.overallHomeFilter);
        },
        overallStadiumFilterChanged() {
            this.updateOverallStadiumFilter(this.overallStadiumFilter);
        },
        calculateResultText(item) {
            if (item.homeTeam && item.homeTeam.id == this.overallTeam.id) {
                return `${item.homeGoals}-${item.homePoints} ${item.awayGoals}-${item.awayPoints}`
            } else {
                return `${item.awayGoals}-${item.awayPoints} ${item.homeGoals}-${item.homePoints}`
            }
        },
        calculateResultColour(item) {
            if (item.homeTeam && item.homeTeam.id == this.overallTeam.id) {
                if (item.result == 'HOME_WIN') {
                    return 'color: green';
                } else if (item.result == 'AWAY_WIN') {
                    return 'color: red';
                } else {
                    return '';
                }
            } else {
                if (item.result == 'HOME_WIN') {
                    return 'color: red';
                } else if (item.result == 'AWAY_WIN') {
                    return 'color: green';
                } else {
                    return '';
                }
            }
        },
        populateQueryParams() {

            if (this.$router.currentRoute.query.team) {
                let queryString = this.$router.currentRoute.query.team;
                this.overallTeam = this.getTeamsHurlingSort.find(it => it.id == queryString);
                this.updateOverallResults();
            }
            if (this.$router.currentRoute.query.opposition) {
                let queryParams = this.$router.currentRoute.query.opposition;
                if (Array.isArray(queryParams)) {
                    for (let i = 0; i < queryParams.length; i++) {
                        if (isNaN(queryParams[i])) {
                            this.overallOpposition.push({ name: queryParams[i] });
                        } else {
                            this.overallOpposition.push(this.getTeamsHurlingSort.find(it => it.id == queryParams[i]))
                        }
                    }
                } else {
                    if (isNaN(queryParams)) {
                        this.overallOpposition.push({ name: queryParams });
                    } else {
                        this.overallOpposition.push(this.getTeamsHurlingSort.find(it => it.id == queryParams))
                    }
                }

                this.updateOverallFilter();
            }
            if (this.$router.currentRoute.query.to) {
                this.overallWinnerYearToFilter = this.$router.currentRoute.query.to;
                this.overallWinnerYearToFilterChanged();
            }
        },
        goToProfile(id) {
            this.$router.push(`/app/${this.$router.currentRoute.meta.sport}/statistics/profile/${id}`)
        }
    }
}
</script>

<style></style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: max-height 0.5s ease-in;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    max-height: 0;
}
</style>